import styled from 'styled-components';
import { GetServerSideProps } from 'next';
import { useContext, useEffect } from 'react';
import { getTelemetry } from 'utils';
import { StandSwitcher, showErrorNotification, LoginForm } from 'components';
import { usersEntryPages } from 'const';
import { AuthContext } from 'store';

import { getServerUser } from '@/api/auth';

const VideoBackground = styled.video`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    min-height: 600px;
    object-fit: cover;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100vh;
    min-height: 600px;
`;

export default function Auth() {
    const { login } = useContext(AuthContext);

    const handleSubmit = async (email: string, password: string, afterSubmit: (isAuthorized: boolean) => void) => {
        try {
            await login(email, password);
        } catch {
            showErrorNotification('Неправильный логин или пароль');
            afterSubmit(false);
        }
    };

    useEffect(() => {
        const FMP = Math.round(performance.now());
        const telemetry = getTelemetry();

        telemetry.startMonitoring();
        telemetry.webApp.setMetric('FMP', FMP);
        telemetry.webApp.send();
    }, []);

    return (
        <Wrapper>
            <VideoBackground autoPlay muted loop>
                <source src="/assets/video/auth.mp4" type="video/mp4" />
            </VideoBackground>
            <LoginForm onSubmit={handleSubmit} />
            <StandSwitcher />
        </Wrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
    const user = await getServerUser(req as any).catch(() => null);

    if (user) {
        return {
            redirect: {
                destination: usersEntryPages[user.role],
                permanent: false,
            },
            props: { user },
        };
    }

    return {
        props: {},
    };
};
